import { reactive, defineComponent } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
    name: "CompanyRegister",
    setup: function () {
        var router = useRouter();
        var state = reactive({
            showPolicyPopup: false,
            listBreadcrumb: [
                {
                    route: '/', name: "TOP"
                },
                {
                    route: '', name: "ログイン・会員登録"
                }
            ]
        });
        return {
            state: state
        };
    }
});
